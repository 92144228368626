const RESOURCE_URL = "monitoring/alerts";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    update({ id, state }) {
      return connection
        .post(RESOURCE_URL + "/update", { json: { id, state } })
        .json();
    },

    addFavorites(ids = []) {
      return connection
        .post(RESOURCE_URL + "/favorites/add", {
          json: {
            ids,
          },
        })
        .json();
    },

    deleteFavorites(ids = []) {
      return connection
        .post(RESOURCE_URL + "/favorites/delete", {
          json: {
            ids,
          },
        })
        .json();
    },
  };
}
