import Vue from "vue";
import App from "./App";
import init from "./router";
import store from "./store";
import i18n from "./i18n";

// Vue filters
import "./filters";

// HTTP Client
import api from "./api";
Vue.prototype.$api = api;

// Sticky
import Sticky from "vue-sticky-directive";
Vue.use(Sticky);

// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Vue Static
import VueStatic from "vue-static";
Vue.use(VueStatic);

// Vue Clipboard
import Clipboard from "v-clipboard";
Vue.use(Clipboard);

// Calcite Components
import {
  applyPolyfills as applyPolyfillsC,
  defineCustomElements as defineCustomElementsC,
} from "@esri/calcite-components/dist/loader";
applyPolyfillsC().then(() => {
  defineCustomElementsC(window, {
    resourcesUrl: process.env.VUE_APP_RESOURCES_URL,
  });
});

// ECharts components
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { BarChart, PieChart, LineChart, ScatterChart } from "echarts/charts";
import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  MarkAreaComponent,
  MarkLineComponent,
  VisualMapComponent,
} from "echarts/components";
echarts.use([
  SVGRenderer,
  BarChart,
  PieChart,
  LineChart,
  ScatterChart,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  MarkAreaComponent,
  MarkLineComponent,
  VisualMapComponent,
]);
Vue.prototype.$echarts = echarts;
Vue.prototype.$echarts.registerTheme(
  "calcite",
  require("@/assets/chart-themes/calcite.json")
);

// Used to annotate custom or imported component libs so Vue doesn't flag them as unknown
Vue.config.ignoredElements = [/esri-\w*/, /calcite-\w*/];
Vue.config.productionTip = false;

// Set global minute level
let tickInterval = 60000,
  lastTick = new Date(null),
  interval;

function tick() {
  if (document.visibilityState === "visible") {
    lastTick = new Date();
    window.dispatchEvent(new Event("agm.update"));
  }
}

function refreshTick() {
  if (
    document.visibilityState === "visible" &&
    new Date() - lastTick >= tickInterval
  ) {
    clearInterval(interval);
    tick();
    interval = setInterval(tick, tickInterval);
  }
}
refreshTick();
document.addEventListener("visibilitychange", refreshTick);

(async () => {
  const response = await api.setup.index();
  Vue.prototype.$mode = response.properties.system.cluster.mode;
  const router = init();
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
})();
