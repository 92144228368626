import { getStorage, setStorage, clearStorage } from "../../utils/storage";

const RESOURCE_URL = "auth";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    //
    // Authentication methods
    //
    signOut() {
      clearStorage();
      window.location.reload();
    },

    signIn({ username, password, remember_me = false }) {
      return connection
        .post(RESOURCE_URL + "/token", {
          json: {
            username,
            password,
            issue_refresh_token: true,
          },
        })
        .json()
        .then(async (response) => {
          setStorage({ ...response }, remember_me);
          return response;
        });
    },

    refreshToken() {
      return connection
        .post(RESOURCE_URL + "/token", {
          json: {
            exchange_refresh_token: true,
            refresh_token: getStorage().refresh_token,
          },
        })
        .json()
        .then((response) => {
          setStorage(response);
          return response;
        });
    },

    //
    // User profile methods
    //
    getProfile() {
      return connection
        .get(RESOURCE_URL + "/self")
        .json()
        .then((response) => response.user);
    },
    updateProfile({
      email,
      name_first,
      name_last,
      security_question_id,
      security_question_answer,
      settings,
    }) {
      return connection
        .post(RESOURCE_URL + "/self/updateProfile", {
          json: {
            email,
            name_first,
            name_last,
            security_question_id,
            security_question_answer,
            settings,
          },
        })
        .json();
    },

    updatePasswordRecoveryQuestions({
      password,
      security_question_id,
      security_question_answer,
    }) {
      return connection
        .post(RESOURCE_URL + "/self/updateProfile", {
          json: {
            password,
            security_question_id,
            security_question_answer,
          },
        })
        .json();
    },

    resetPassword({ old_password, password, password_confirmation }) {
      return connection
        .post(RESOURCE_URL + "/self/resetPassword", {
          json: {
            old_password,
            password,
            password_confirmation,
            issue_refresh_token: true,
          },
        })
        .json();
    },

    //
    // Password recovery methods
    //
    getSecurityQuestion({ username }) {
      return connection
        .post(RESOURCE_URL + "/forgotPassword", {
          json: {
            username,
          },
        })
        .json();
    },
    getToken({ username, security_question_answer }) {
      return connection
        .post(RESOURCE_URL + "/forgotPassword", {
          json: {
            username,
            security_question_answer,
          },
        })
        .json();
    },
    recoverPassword({ token, password, password_confirmation }) {
      return connection
        .post(RESOURCE_URL + "/recoverPassword", {
          json: {
            recovery_token: token,
            password,
            password_confirmation,
          },
        })
        .json();
    },

    //
    // Misc. util methods
    //
    checkPasswordStrength({ password }) {
      return connection
        .post(RESOURCE_URL + "/checkPasswordStrength", {
          json: {
            password,
          },
        })
        .json();
    },

    //
    // Favorites
    //
    getFavorites() {
      return connection
        .get(RESOURCE_URL + "/self/favorites")
        .then((response) => response.favorites)
        .json();
    },
    addFavorite(id, type) {
      return connection
        .post(RESOURCE_URL + "/self/favorites/add", {
          json: {
            type,
            ids: [id],
          },
        })
        .json()
        .then((response) => response.favorites);
    },
    deleteFavorite(id) {
      return connection
        .post(RESOURCE_URL + "/self/favorites/delete", { json: { ids: [id] } })
        .json()
        .then((response) => response.favorites);
    },
  };
}
