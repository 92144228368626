import Alerts from "./alerts";
import Analyses from "./analyses";
import Components from "./components";
import ComponentsLogs from "./components_logs";
import Comments from "./comments";
import Collections from "./collections";
import Incidents from "./incidents";
import Metrics from "./metrics";
import MetricsData from "./metrics_data";
import Notifications from "./notifications";
import Observers from "./observers";
import Labels from "./labels";
import Agents from "./agents";

const RESOURCE_URL = "monitoring";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },
    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },
    agents: Agents(connection),
    alerts: Alerts(connection),
    analyses: Analyses(connection),
    collections: Collections(connection),
    comments: Comments(connection),
    components: Components(connection),
    components_logs: ComponentsLogs(connection),
    incidents: Incidents(connection),
    labels: Labels(connection),
    metrics: Metrics(connection),
    metrics_data: MetricsData(connection),
    notifications: Notifications(connection),
    observers: Observers(connection),
  };
}
