const RESOURCE_URL = "monitoring/notifications";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    add({
      name,
      description = null,
      webhook_url = null,
      collection_id,
      user_id,
      is_webhook_enabled = false,
      webhook_delivery_failures,
      webhook_delivery_timeout,
      webhook_delivery_reattempt_interval,
      webhook_secret,
      is_email_enabled = false,
      email_interval,
      events = [],
    }) {
      return connection
        .post(RESOURCE_URL + "/add", {
          json: {
            name,
            description,
            webhook_url,
            is_webhook_enabled,
            webhook_delivery_failures,
            webhook_delivery_timeout,
            webhook_delivery_reattempt_interval,
            webhook_secret,
            is_email_enabled,
            collection_id,
            user_id,
            email_interval,
            events,
          },
        })
        .json();
    },

    update({
      id,
      is_webhook_enabled,
      is_email_enabled,
      webhook_delivery_failures,
      webhook_delivery_timeout,
      webhook_delivery_reattempt_interval,
      webhook_secret,
      name,
      description,
      webhook_url,
      email_interval,
      events,
    }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            id,
            is_webhook_enabled,
            is_email_enabled,
            webhook_delivery_failures,
            webhook_delivery_timeout,
            webhook_delivery_reattempt_interval,
            webhook_secret,
            name,
            description,
            webhook_url,
            email_interval,
            events,
          },
        })
        .json();
    },

    delete({ ids = [] }) {
      return connection
        .post(RESOURCE_URL + "/delete", { json: { ids } })
        .json();
    },

    test({ id }) {
      return connection.post(RESOURCE_URL + "/test", { json: { id } }).json();
    },

    events: {
      index() {
        return connection.get(RESOURCE_URL + "/events").json();
      },
    },

    recipients: {
      index() {
        return connection.get(RESOURCE_URL + "/recipients").json();
      },

      query(query = {}) {
        if (!query.where || query.where.length === 0) query.where = "1=1";
        return connection
          .post(RESOURCE_URL + "/recipients/query", { json: query })
          .json();
      },

      add({ contacts = [] }) {
        return connection
          .post(RESOURCE_URL + "/recipients/add", { json: { contacts } })
          .json();
      },

      attach({ id, recipients = [] }) {
        return connection
          .post(RESOURCE_URL + "/recipients/attach", {
            json: {
              id,
              recipients,
            },
          })
          .json();
      },

      detach({ id, recipients = [] }) {
        return connection
          .post(RESOURCE_URL + "/recipients/detach", {
            json: {
              id,
              recipients,
            },
          })
          .json();
      },
    },
  };
}
