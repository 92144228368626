import store from "@/store";
import { setStorage } from "../../utils/storage";

const RESOURCE_URL = "setup";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },
    createInitialAdmin({
      name_first,
      name_last,
      email,
      username,
      password,
      password_confirmation,
    }) {
      return connection
        .post(RESOURCE_URL + "/createInitialAdmin", {
          json: {
            name_first,
            name_last,
            email,
            username,
            password,
            password_confirmation,
          },
        })
        .json()
        .then(async (response) => {
          setStorage({ ...response });
          await store.dispatch("user/loadProfile");
          return response;
        });
    },
    authServer({ server_url, username, password }) {
      return connection
        .post(RESOURCE_URL + "/authServer", {
          json: {
            server_url,
            username,
            password,
          },
        })
        .json()
        .then(async (response) => {
          await store.dispatch("server/setServerLogin", {
            json: {
              ...response,
              server_url,
            },
          });
          return response;
        });
    },
    listAgents({ server_url, server_token }) {
      return connection
        .post(RESOURCE_URL + "/listAgents", {
          json: {
            server_url,
            server_token,
          },
        })
        .json();
    },
    registerAgent({ server_url, server_token, agent_id, name, description }) {
      return connection
        .post(RESOURCE_URL + "/registerAgent", {
          json: {
            server_url,
            server_token,
            agent_id,
            name,
            description,
          },
        })
        .json()
        .then(async (response) => {
          await store.dispatch("server/setCreatedAgent", { ...response, name });
          return response;
        });
    },
    unregisterAgent({ server_url, server_token }) {
      return connection
        .post(RESOURCE_URL + "/unregisterAgent", {
          json: {
            server_url,
            server_token,
          },
        })
        .json()
        .then(async (response) => {
          await store.dispatch("server/setCreatedAgent", {});
          return response;
        });
    },
  };
}
