import Vue from "vue";
import Vuex from "vuex";

import agent from "./agent";
import alert from "./alert";
import analysis from "./analysis";
import collection from "./collection";
import component from "./component";
import labels from "./labels";
import passwordReset from "./password-reset";
import schema from "./schema";
import server from "./server";
import toasts from "./toasts";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agent,
    alert,
    analysis,
    collection,
    component,
    labels,
    passwordReset,
    schema,
    server,
    toasts,
    user,
  },
});
