<template>
  <div>
    <calcite-alert
      v-for="toast of toasts"
      icon
      :key="toast.id"
      :open="toast.active"
      :auto-close="toast.auto"
      :kind="alertStatus(toast)"
      auto-close-duration="fast"
      @calciteAlertClose="onAlertClose(toast)"
    >
      <div slot="title">{{ toast.title }}</div>
      <div slot="message">
        {{ toast.message }}
      </div>
      <calcite-link
        v-if="toast.action"
        slot="link"
        :href="toast.action.href || ''"
        @click="
          () => {
            toast.action.func && toast.action.func();
            onAlertClose(toast);
          }
        "
        >{{ toast.action.label }}
      </calcite-link>
    </calcite-alert>
  </div>
</template>
<script>
export default {
  name: "GlobalAlertHandler",
  computed: {
    toasts() {
      return this.$store.state.toasts.toasts;
    },
  },
  methods: {
    async onAlertClose(toast) {
      this.$store.dispatch("toasts/delete", toast.id);
    },
    alertStatus(toast) {
      return toast.status === "error"
        ? "danger"
        : toast.status === "success"
        ? "success"
        : "info";
    },
  },
};
</script>
<style lang="scss" scoped></style>
