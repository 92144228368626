<template>
  <div class="esri-header-brand">
    <router-link
      :to="{ name: 'Home' }"
      exact
      class="focus-border"
      :tabindex="screenSizeOverlay ? -1 : undefined"
    >
      <h1 class="esri-header-brand-link -fit-burger">
        <span class="brand-image">
          <slot name="logo"></slot>
        </span>
        <span class="font-size-0">
          <slot name="name"></slot>
        </span>
      </h1>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NavBrand",
  props: {
    screenSizeOverlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.esri-header-brand a {
  color: var(--calcite-ui-text-2);
  transition: 0.2s;
  &:hover {
    color: var(--calcite-ui-text-1);
    text-decoration: none;
  }
}
</style>
