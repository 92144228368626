const RESOURCE_URL = "monitoring/analyses";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    add({ name, description, configuration }) {
      return connection
        .post(RESOURCE_URL + "/add", {
          json: {
            name,
            description,
            configuration,
          },
        })
        .json();
    },

    update({ id, name, description, configuration }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            id,
            name,
            description,
            configuration,
          },
        })
        .json();
    },

    delete(id) {
      return connection
        .post(RESOURCE_URL + "/delete", {
          json: {
            id,
          },
        })
        .json();
    },

    default(id) {
      return connection
        .post(RESOURCE_URL + "/default", {
          json: {
            id,
          },
        })
        .json();
    },

    select(id) {
      return connection
        .post(RESOURCE_URL + "/select", {
          json: {
            id,
          },
        })
        .json();
    },
  };
}
