/**
 * STATE
 */
const state = {
  // password reset steps:
  // 1 - get question (submit username)
  // 2 - get token (submit security_question_answer)
  // 3 - reset password
  passwordResetStep: 1,
  canSubmit: false,
  loadStatus: null,
  error: null,
  submitStatusIsPending: null,
  success: null,
  // fields
  username: null,
  security_details: null,
  security_question: null,
  security_question_answer: null,
  recovery_token: null,
  password: null,
  password_confirmation: null,
};

/**
 * GETTERS
 */
const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  setCanSubmit(state, canSubmit) {
    state.canSubmit = canSubmit;
  },
  setError(state, error) {
    state.error = error || null;
  },
  setSuccess(state) {
    state.success = true;
  },
  setSubmitStatusIsPending(state, bool) {
    state.submitStatusIsPending = bool;
  },
  setPasswordResetStep(state, step) {
    state.passwordResetStep = step;
  },
  setUsername(state, username) {
    state.username = username;
  },
  setAnswer(state, answer) {
    state.security_question_answer = answer;
  },
  setSecurityDetails(state, details) {
    state.security_details = details;
  },
  setSecurityQuestion(state, question) {
    state.security_question = question;
  },
  setPasswords(state, { password, password_confirmation }) {
    state.password = password;
    state.password_confirmation = password_confirmation;
  },
  setToken(state, token) {
    state.recovery_token = token;
  },
  clearPasswordResetState(state) {
    Object.keys(state).forEach((n) => (state[n] = null));
    state.passwordResetStep = 1;
  },
};

/**
 * ACTIONS
 */
const actions = {
  setToken({ commit }, { token }) {
    commit("setToken", token);
    commit("setPasswordResetStep", 3);
  },

  // ---------- Step 1 ----------
  setUsername({ commit }, { username }) {
    commit("setUsername", username);
  },
  async getSecurityQuestion({ commit, state }) {
    commit("setError"); // clear error
    commit("setSubmitStatusIsPending", true);
    try {
      const res = await this._vm.$api.auth.getSecurityQuestion({
        username: state.username,
      });
      commit("setSecurityDetails", res.details);
      commit("setSecurityQuestion", res.question);
      commit("setPasswordResetStep", 2);
    } catch (err) {
      commit("setError", err.error);
    }
    commit("setSubmitStatusIsPending", false);
  },

  // ---------- Step 2 ----------
  setAnswer({ commit }, { answer }) {
    commit("setAnswer", answer);
  },
  async getToken({ commit, state }) {
    commit("setError"); // clear error
    commit("setSubmitStatusIsPending", true);
    try {
      const res = await this._vm.$api.auth.getToken({
        username: state.username,
        security_question_answer: state.security_question_answer,
      });
      if (res.recovery_token) {
        commit("setToken", res.recovery_token);
        commit("setPasswordResetStep", 3);
      }
    } catch (err) {
      commit("setError", err.error);
    }
    commit("setSubmitStatusIsPending", false);
  },

  // ---------- Step 3 ----------
  setPasswords({ commit }, { password, password_confirmation }) {
    commit("setPasswords", { password, password_confirmation });
  },
  setCanSubmit({ commit }, { canSubmit }) {
    commit("setCanSubmit", canSubmit);
  },
  async resetPassword({ commit, state }) {
    commit("setError"); // clear error
    commit("setSubmitStatusIsPending", true);
    try {
      await this._vm.$api.auth.recoverPassword({
        token: state.recovery_token,
        password: state.password,
        password_confirmation: state.password_confirmation,
      });
      await commit("setSuccess");
      commit("clearPasswordResetState");
    } catch (err) {
      commit("setError", err.error);
    }
    commit("setSubmitStatusIsPending", false);
  },
  // exit process
  clearPasswordResetState({ commit }) {
    commit("clearPasswordResetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
