<template>
  <calcite-notice
    open
    icon="exclamation-mark-triangle"
    scale="m"
    width="full"
    kind="danger"
    class="override"
  >
    <div slot="title">
      {{ error.message || $t("globalErrorMessages.generic") }}
    </div>
    <div slot="message" class="capitalize">
      {{ error.details.join(". ") }}
    </div>
  </calcite-notice>
</template>

<script>
export default {
  name: "NoticeApiError",
  props: {
    error: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.override {
  background-color: var(--calcite-ui-background);
  box-shadow: none;
}
</style>
