const RESOURCE_URL = "admin/logs";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    update({ level, retention }) {
      return connection
        .post(RESOURCE_URL + "/update", { json: { level, retention } })
        .json();
    },

    clear() {
      return connection.post(RESOURCE_URL + "/clear").json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },
  };
}
