const RESOURCE_URL = "admin/security";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL);
    },
    update({ protocols, hstsEnabled }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            protocols,
            hstsEnabled,
          },
        })
        .json();
    },
    importSSL({ cert_password, cert_file }) {
      const body = new FormData();
      body.append("cert_file", cert_file);
      body.append("cert_password", cert_password);

      return connection
        .post(RESOURCE_URL + "/ssl/import", {
          headers: { "Content-Type": undefined }, // apparently this needs to be set to undefined due to Fetch API requirements
          body,
        })
        .json();
    },
    users: {
      index() {
        return connection.get(RESOURCE_URL + "/users").json();
      },
      add({ username, password, name_first, name_last, email, role }) {
        return connection
          .post(RESOURCE_URL + "/users/add", {
            json: {
              username,
              password,
              name_first,
              name_last,
              email,
              role,
            },
          })
          .json();
      },
      query(query = {}) {
        if (!query.where || query.where.length === 0) query.where = "1=1";
        return connection
          .post(RESOURCE_URL + "/users/query", { json: query })
          .json();
      },
      resource(id) {
        return connection.get(RESOURCE_URL + `/users/${id}`).json();
      },
      delete(id) {
        return connection.post(RESOURCE_URL + "/users/delete", {
          json: { id },
        });
      },
      update({ id, name_first, name_last, email, role }) {
        return connection
          .post(RESOURCE_URL + "/users/update", {
            json: {
              id,
              name_first,
              name_last,
              email,
              role,
            },
          })
          .json();
      },
      forgotPassword(id) {
        return connection
          .post(RESOURCE_URL + "/users/forgotPassword", {
            json: {
              id,
            },
          })
          .json();
      },
      updatePolicy({
        tokenExpiration,
        minlength,
        minletter,
        minlower,
        minupper,
        mindigit,
        minother,
      }) {
        return connection
          .post(RESOURCE_URL + "/update", {
            json: {
              users: {
                tokenExpiration,
                passwordPolicy: {
                  minlength,
                  minletter,
                  minlower,
                  minupper,
                  mindigit,
                  minother,
                },
              },
            },
          })
          .json();
      },
      resetPolicy() {
        return connection
          .post(RESOURCE_URL + "/reset", { json: ["users.passwordPolicy"] })
          .json();
      },
    },
    agents: {
      index() {
        return connection.get(RESOURCE_URL + "/agents").json();
      },
      add({ name, description }) {
        return connection
          .post(RESOURCE_URL + "/agents/add", {
            json: {
              name,
              description,
            },
          })
          .json();
      },
      update({ id, name, description, is_enabled }) {
        return connection
          .post(RESOURCE_URL + "/agents/update", {
            json: {
              id,
              name,
              description,
              is_enabled,
            },
          })
          .json();
      },
      unregister({ id }) {
        return connection
          .post(RESOURCE_URL + "/agents/unregister", {
            json: {
              id,
            },
          })
          .json();
      },
      query(query = {}) {
        if (!query.where || query.where.length === 0) query.where = "1=1";
        return connection
          .post(RESOURCE_URL + "/agents/query", { json: query })
          .json();
      },
      delete(id) {
        return connection.post(RESOURCE_URL + "/agents/delete", {
          json: { id },
        });
      },
      generateToken({ id }) {
        return connection
          .post(RESOURCE_URL + "/agents/generateToken", {
            json: { id },
          })
          .json();
      },
    },
  };
}
