<template>
  <transition mode="out-in" name="fade">
    <div id="app" v-if="isInitialized || !showGlobalNav">
      <GlobalModals />
      <TopNav v-if="$mode === 'server' && showGlobalNav" />
      <transition mode="out-in" name="fade">
        <router-view />
      </transition>
      <GlobalAlertHandler />
      <ScreenSizeOverlay />
    </div>
    <load-initial class="padding-leader-3" v-else />
  </transition>
</template>
<script>
// App Components
import TopNav from "@/partials/navigation/TopNav";
import GlobalAlertHandler from "@/partials/other/GlobalAlertHandler";
import GlobalModals from "@/partials/other/GlobalModals";
import LoadInitial from "@/partials/other/LoadInitial";
import ScreenSizeOverlay from "@/partials/other/ScreenSizeOverlay";

// Mixins
import globalPopoverOverlay from "@/mixins/global-popover-overlay-mixin";

export default {
  name: "App",
  mixins: [globalPopoverOverlay],
  components: {
    TopNav,
    GlobalAlertHandler,
    GlobalModals,
    LoadInitial,
    ScreenSizeOverlay,
  },
  data() {
    return {
      showGlobalNav: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.evalGlobalNav();
      },
    },
  },
  computed: {
    isInitialized() {
      return this.$mode === "server"
        ? this.$store.state.user.isInitialized &&
            this.$store.state.schema.isInitialized
        : true;
    },
  },
  methods: {
    evalGlobalNav() {
      if (this.$mode === "agent") {
        return false;
      }
      let showGlobalNav = false;
      this.$route.matched.forEach((record) => {
        if (record.meta?.showGlobalNav) {
          showGlobalNav = true;
        }
      });
      this.showGlobalNav = showGlobalNav;
    },
  },
  beforeMount() {
    this.evalGlobalNav();
    this.$store.dispatch("user/setTheme");
  },
};
</script>

<style lang="scss">
@import "~@esri/calcite-components/dist/calcite/calcite.css";
@import "~esri-global-nav/dist/esri-global-nav.css";

/* @import "~@esri/calcite-colors/colors.scss"; */

@import "@/styles/reflex-grid.scss";
@import "@/styles/calcite-fonts.scss";
@import "@/styles/transitions.scss";
@import "@/styles/styles.scss";
</style>
