const RESOURCE_URL = "monitoring/collections";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    add({
      name,
      description,
      is_service_enabled,
      is_security_enabled,
      expression,
    }) {
      return connection
        .post(RESOURCE_URL + "/add", {
          json: {
            name,
            description,
            is_service_enabled,
            is_security_enabled,
            expression,
          },
        })
        .json();
    },

    update({
      id,
      name,
      description,
      is_service_enabled,
      is_security_enabled,
      expression,
    }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            id,
            name,
            description,
            is_service_enabled,
            is_security_enabled,
            expression,
          },
        })
        .json();
    },

    delete(id) {
      return connection
        .post(RESOURCE_URL + "/delete", {
          json: {
            id,
          },
        })
        .json();
    },

    testCollectionExpression(expression) {
      return connection
        .post(RESOURCE_URL + "/testexpression", { json: expression })
        .json();
    },

    updateComponents({ collectionId, componentId, events = [] }) {
      return connection
        .post(RESOURCE_URL + "/components/update", {
          json: {
            id: collectionId,
            components_id: componentId,
            events,
          },
        })
        .json();
    },
  };
}
