/**
 * STATE
 */
const state = {
  resource: {},
};

/**
 * GETTERS
 */
const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  analysis(state, data) {
    state.resource = data;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async loadAnalysis(context, { id, selectedAnalysis, defaultAnalysis }) {
    let data = await this._vm.$api.monitor.analyses.query({
      where: `id = '${selectedAnalysis ?? id ?? defaultAnalysis}'`,
      including: [{ resource: "users" }],
    });
    if (selectedAnalysis && !data?.features?.length) {
      data = await this._vm.$api.monitor.analyses.query({
        where: `id = '${defaultAnalysis}'`,
        including: [{ resource: "users" }],
      });
    }
    context.commit("analysis", data.features[0]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
