const RESOURCE_URL = "monitoring/observers";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    update({ id, interval, is_enabled }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            id,
            interval,
            is_enabled,
          },
        })
        .json();
    },

    run({ id }) {
      return connection
        .post(RESOURCE_URL + "/run", {
          json: {
            id,
          },
        })
        .json();
    },
  };
}
