const RESOURCE_URL = "admin/monitor";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },
    register(args = {}) {
      return connection.post(RESOURCE_URL + "/register", { json: args }).json();
    },
    unregister() {
      return connection.post(RESOURCE_URL + "/unregister").json();
    },
  };
}
