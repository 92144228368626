const RESOURCE_URL = "monitoring/agents";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },
  };
}
