/**
 * STATE
 */
const state = {
  resource: {},
};

/**
 * GETTERS
 */
const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  collection(state, data) {
    state.resource = data;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async loadCollection(context, { id }) {
    let data = await this._vm.$api.monitor.collections.query({
      where: `id = ${id}`,
      including: [
        {
          resource: "components",
          returnCountOnly: true,
        },
        {
          resource: "notifications",
        },
        {
          resource: "incidents",
        },
        {
          resource: "alerts",
          where: "closed_at IS NULL",
          returnCountOnly: true,
        },
      ],
    });
    context.commit("collection", data.features[0]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
