const RESOURCE_URL = "monitoring/incidents";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    add({
      name,
      description = null,
      is_monitoring_enabled,
      is_alerting_enabled,
      reminder_hours,
      occurrence,
      collection_ids = [],
    }) {
      return connection
        .post(RESOURCE_URL + "/add", {
          json: {
            name,
            description,
            is_monitoring_enabled,
            is_alerting_enabled,
            reminder_hours,
            collection_ids,
            ...(!!occurrence && { occurrence }),
          },
        })
        .json();
    },

    update({
      id,
      name,
      description,
      is_monitoring_enabled,
      is_alerting_enabled,
      reminder_hours,
      occurrence,
    }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            id,
            name,
            description,
            is_monitoring_enabled,
            is_alerting_enabled,
            reminder_hours,
            occurrence: Object.keys(occurrence || {}).length
              ? occurrence
              : null,
          },
        })
        .json();
    },

    delete({ ids = [] }) {
      return connection
        .post(RESOURCE_URL + "/delete", { json: { ids } })
        .json();
    },

    collections: {
      index() {
        return connection.get(RESOURCE_URL + "/collections").json();
      },

      attach({ id, collection_ids = [] }) {
        return connection
          .post(RESOURCE_URL + "/collections/attach", {
            json: {
              id,
              collection_ids,
            },
          })
          .json();
      },

      detach({ id, collection_ids = [] }) {
        return connection
          .post(RESOURCE_URL + "/collections/detach", {
            json: {
              id,
              collection_ids,
            },
          })
          .json();
      },
    },
  };
}
