export default {
  data: function () {
    return {
      open: true,
      submitStatus: null,
      isInitialized: false,
    };
  },
  computed: {
    isPending() {
      return (
        !this.isInitialized ||
        ["PENDING", "SUCCESS"].includes(this.submitStatus)
      );
    },
  },
  methods: {
    // method to programmatically close the Modal.
    close() {
      this.open = false;
    },
    // called by the calcite modal after closing.
    onClose() {
      this.$emit("close", { refresh: this.submitStatus === "SUCCESS" });
    },
  },
  mounted() {
    // Find the modal in the DOM and set the user's focus within it
    document.querySelector("calcite-modal")?.setFocus();
  },
};
