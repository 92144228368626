const RESOURCE_URL = "admin/system";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    restart() {
      return connection.post(RESOURCE_URL + "/restart").json();
    },

    update({
      webContextUrl,
      clusterSize,
      clientRequestTimeout,
      keepAliveTimeout,
      maxRecordCount,
    }) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            webContextUrl,
            clusterSize,
            clientRequestTimeout,
            maxRecordCount,
            keepAliveTimeout,
          },
        })
        .json();
    },

    completeSetup() {
      return connection.post(RESOURCE_URL + "/completeSetup").json();
    },

    mail: {
      index() {
        return connection.get(RESOURCE_URL + "/mail").json();
      },
      register({ address, encryption_method, sender, username, password }) {
        return connection
          .post(RESOURCE_URL + "/mail/register", {
            json: _parsePayload({
              address,
              encryption_method,
              sender,
              username,
              password,
            }),
          })
          .json();
      },
      unregister() {
        return connection.post(RESOURCE_URL + "/mail/unregister").json();
      },
      enable() {
        return connection.post(RESOURCE_URL + "/mail/enable").json();
      },
      disable() {
        return connection.post(RESOURCE_URL + "/mail/disable").json();
      },
      test({ recipients = [] }) {
        return connection
          .post(RESOURCE_URL + "/mail/test", { json: { recipients } })
          .json();
      },
    },
    licenses: {
      index() {
        return connection.get(RESOURCE_URL + "/licenses").json();
      },
      refresh() {
        return connection.post(RESOURCE_URL + "/licenses/refresh").json();
      },
    },
  };
}

function _parsePayload({
  address,
  encryption_method,
  sender,
  username,
  password,
}) {
  let payload = {
    host: address,
    port: null,
    encryption_method,
    sender,
    username,
    password,
  };

  /** Parse connection string for optional port */
  if (payload.host.split(":").length > 0) {
    let parsed = payload.host.split(":");
    payload.host = parsed[0];
    payload.port = parsed[1];
  }

  return payload;
}
