const RESOURCE_URL = "admin/database";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },
    status() {
      return connection.post(RESOURCE_URL + "/status", {}).json();
    },
    update(updates) {
      return connection
        .post(RESOURCE_URL + "/update", { json: updates })
        .json();
    },
    reset(resetFields) {
      return connection
        .post(RESOURCE_URL + "/reset", { json: resetFields })
        .json();
    },
    register({ client, address, database, username, password }) {
      return connection
        .post(RESOURCE_URL + "/register", {
          json: {
            client,
            address,
            database,
            username,
            password,
          },
        })
        .json();
    },
    unregister() {
      return connection.post(RESOURCE_URL + "/unregister").json();
    },
  };
}
