const name = "agm_auth";
const path =
  process.env.VUE_APP_RESOURCES_URL !== "/"
    ? process.env.VUE_APP_RESOURCES_URL.replace(/\/+$/, "")
    : process.env.VUE_APP_RESOURCES_URL; // removes trailing slash for proper cookie handling

export function getStorage() {
  let cookie = {};
  // const search = `; ${document.cookie}`;
  const found = document.cookie
    .split(`; `)
    .find((part) => part.startsWith(name));
  if (found) cookie = JSON.parse(found.split("=")[1]);
  return cookie;
}

export function setStorage(
  { access_token, refresh_token, expires_in },
  remember_me
) {
  const cookie = {
    [name]: JSON.stringify({
      access_token,
      refresh_token,
      expires_in,
    }),
    path: path,
    ...(remember_me && { "max-age": 60 * 60 * 24 * 30 }), // 30 days
  };
  document.cookie = Object.keys(cookie)
    .map((o) => `${o}=${cookie[o]}`)
    .join("; ");
}

export function clearStorage() {
  document.cookie = `${name}=; path: "${path}"; max-age=-1`;
}
