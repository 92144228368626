/**
 * STATE
 */
const state = {
  features: [],
};

/**
 * GETTERS
 */
const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  features(state, features) {
    state.features = features;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async load(context, queryParams) {
    const { features } = await this._vm.$api.monitor.labels.query({
      where: "1=1",
      ...queryParams,
    });

    context.commit("features", features);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
