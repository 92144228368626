// Utils
import uuid from "@/utils/uuid.js";

// Third Party
import { isEqual } from "lodash";

/**
 * STATE
 */
const state = {
  toasts: [],
};

/**
 * GETTERS
 */

const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  add(state, toast) {
    state.toasts.push(toast);
  },
  close(state, id) {
    const toast = state.toasts.find((toast) => toast.id === id);
    if (toast) toast.active = false;
  },
  delete(state, id) {
    const idx = state.toasts.findIndex((toast) => toast.id === id);
    if (idx > -1) state.toasts.splice(idx, 1);
  },
};

/**
 * ACTIONS
 */
const actions = {
  async add(
    { commit, state },
    {
      status,
      title,
      message,
      action, // { label: "label text", [func: someFunction], [href: 'somelink.com'] }
      auto = true, // should the toast close automatically
      active = true,
    }
  ) {
    const id = uuid();

    // Evaluate if this new toast is unique
    let isUnique = true;
    state.toasts.forEach((toast) => {
      if (
        isEqual(
          { ...toast, id: undefined },
          {
            status,
            title,
            message,
            action,
            auto,
            active,
            id: undefined,
          }
        )
      ) {
        isUnique = false;
      }
    });

    if (isUnique) {
      commit("add", {
        id,
        status,
        title,
        message,
        action,
        auto,
        active,
      });
    }

    return id;
  },
  async close(context, id) {
    context.commit("close", id);
  },
  async delete(context, id) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    context.commit("delete", id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
