<template>
  <div class="screen-size-overlay">
    <div class="screen-size-content">
      <img src="@/assets/logos/ArcGIS_Monitor_256.png" />
      <h3 class="font-size-2">
        {{ $t("common.productName") }}
      </h3>
      <p>{{ $t("globalErrorMessages.screenSize") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenSizeOverlay",
  components: {},
};
</script>
<style lang="scss" scoped>
$max-width: 160px;

.screen-size-overlay {
  display: none;
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  box-sizing: border-box;
  padding: 2rem;
  background: var(--calcite-ui-background);

  @media screen and (max-width: 1023px) {
    display: block;
  }
}

.screen-size-content {
  background: var(--calcite-ui-foreground-1);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: $max-width;
    margin-bottom: 2rem;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: $max-width * 2.5;
    text-align: center;
    margin-bottom: 2rem;
  }
}
</style>
