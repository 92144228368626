<template>
  <ServerRestart
    v-if="$store.state.server.promptRestart"
    @close="$store.dispatch('server/promptServerRestart', false)"
  />
</template>
<script>
// App Components
import ServerRestart from "@/partials/modals/ServerRestart";

export default {
  name: "GlobalModals",
  components: {
    ServerRestart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
