/**
 * STATE
 */
const state = {
  resource: {},
  fields: [],
};

/**
 * GETTERS
 */
const getters = {
  registered: (state) => state.resource.attributes.is_registered,
  agent: (state) => state.resource,
  components: (state) => state.resource.components,
  count: (state, getters) => getters.components.length,
};

/**
 * MUTATIONS
 */
const mutations = {
  agent(state, data) {
    state.resource = data;
  },
  fields(state, fields) {
    state.fields = fields;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async loadAgent(context, { id }) {
    let data = await this._vm.$api.admin.security.agents.query({
      where: `id = ${id}`,
      including: [
        {
          resource: "components",
          returnCountOnly: true,
        },
      ],
    });
    context.commit("fields", data.fields); // Queries don't give back fields.
    context.commit("agent", data.features[0]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
