const RESOURCE_URL = "monitoring/comments";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    add({ alert_id, incident_id, message }) {
      return connection
        .post(RESOURCE_URL + "/add", {
          json: {
            alert_id,
            incident_id,
            message,
          },
        })
        .json();
    },

    delete(ids) {
      return connection
        .post(RESOURCE_URL + "/delete", { json: { ids } })
        .json();
    },

    update({ id, message }) {
      return connection
        .post(RESOURCE_URL + "/update", { json: { id, message } })
        .json();
    },
  };
}
