import ky from "ky";

import { getStorage } from "../utils/storage";

import auth from "./auth";

const connection = _newConnetionInstance();

const _AUTH = auth(connection);
let _TOKEN_GENERATING = false;
let _TOKEN_GENERATOR;

const _RETRY_ERRORS = ["E_AUTH_TOKEN_EXPIRED"];

const _SYSTEM_ERRORS = [
  "E_DATABASE_NOT_CONNECTED",
  "E_DATABASE_NOT_REGISTERED",
];

function _newConnetionInstance() {
  return ky.extend({
    prefixUrl: process.env.VUE_APP_API_BASE_URL,
    https: {
      rejectUnauthorized: false,
    },
    timeout: false,
    hooks: {
      beforeRequest: [
        (request) => {
          setToken(request);
        },
      ],
      afterResponse: [
        async (request, options, response) => {
          const body = await response.json();
          if (response.status === 401) {
            if (_RETRY_ERRORS.includes(body.error?.code)) {
              await _refreshAccessToken();
              setToken(request);
              return connection(request);
            } else {
              window.dispatchEvent(new Event("agm.signout"));
            }
          } else if (_SYSTEM_ERRORS.includes(body.error?.code)) {
            window.dispatchEvent(new Event("agm.systemerror"));
          }
        },
      ],
      beforeError: [async (error) => await error.response.json()],
    },
  });
}

/**
 * This function sets the access token on a Ky Request
 */
function setToken(request) {
  const { access_token } = getStorage();
  request.headers.set("Authorization", "Bearer " + access_token);
}

/**
 * This function manages the state of token generation to only allow a single token generated at a time
 * The first failing request will set _TOKEN_GENERATING to true and set the promise, while subsequent
 * requests will await on the promise to fullfill
 */
async function _refreshAccessToken() {
  if (!_TOKEN_GENERATING) {
    _TOKEN_GENERATING = true;
    _TOKEN_GENERATOR = _AUTH
      .refreshToken()
      .finally(() => (_TOKEN_GENERATING = false));
  }
  await _TOKEN_GENERATOR;
}

export default connection;
