import { render, staticRenderFns } from "./LoadInitial.vue?vue&type=template&id=4b7e2349&scoped=true&"
import script from "./LoadInitial.vue?vue&type=script&lang=js&"
export * from "./LoadInitial.vue?vue&type=script&lang=js&"
import style0 from "./LoadInitial.vue?vue&type=style&index=0&id=4b7e2349&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7e2349",
  null
  
)

export default component.exports