// This mixin is responsible for inserting full screen overlays behind
// popover elements with the 'prevent-scroll' attribute. This overlay
// prevents the user from scrolling or interacting with the rest of
// the page while the popover is open.

/* eslint-disable */

// Utils
import uuid from "@/utils/uuid";

export default {
  data() {
    return {
      id: uuid(),
    };
  },
  mounted() {
    // Setup mutation observer
    const observer = new MutationObserver((mutationsList, observer) => {
      // Watch elements that have 'prevent-scroll' enabled
      const popovers = Array.prototype.slice
        .call(mutationsList)
        .filter((o) => !!o.target.attributes.getNamedItem("prevent-scroll"))
        .map((o) => o.target);

      // When the popover changes
      popovers.forEach((popover) => {
        // Find any existing overlays
        const existing = document.getElementById(this.id);

        // Append the screen overlay if the popover opened
        if (popover["open"]) {
          if (!existing) {
            const overlay = document.createElement("div");
            overlay.classList.add("agm-popover-overlay");
            overlay.id = this.id;
            popover.parentElement.insertBefore(overlay, popover);
          }
        }

        // Otherwise attempt to remove existing overlay
        else if (existing) {
          document.getElementById(this.id).remove();
        }
      });
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributeFilter: ["aria-hidden"],
    });
  },
};
