<template>
  <header class="topnav-wrapper" id="agm-topnav">
    <button
      class="esri-header-canvas"
      tabindex="-1"
      :data-open="`${canvasActive}`"
      data-state="menu"
      :style="{
        height: canvasActive ? `${canvasHeight}px` : `0px`,
      }"
    ></button>
    <div class="topnav grid-container grid no-wrap grid-bleed">
      <div class="align-center full-height">
        <Brand :screenSizeOverlay="screenSizeOverlay">
          <template v-slot:logo>
            <img
              class="esri-header-brand-image margin-right-half"
              src="@/assets/logos/ArcGIS_Monitor_32.png"
            />
          </template>
          <template v-slot:name>{{ $t("common.productName") }}</template>
        </Brand>

        <nav class="full-height margin-0">
          <ul class="topnav-links">
            <router-link
              class="topnav-link focus-border_inset"
              :to="{ name: 'Home' }"
              exact
              >{{ $t("common.home") }}</router-link
            >
            <router-link
              class="topnav-link focus-border_inset"
              :to="{ name: 'Alerts' }"
              >{{ $t("common.alerts") }}</router-link
            >
            <router-link
              class="topnav-link focus-border_inset"
              :to="{ name: 'Analyses' }"
              >{{ $t("common.analysis") }}</router-link
            >
            <router-link
              class="topnav-link focus-border_inset"
              :to="{ name: 'Collections' }"
              >{{ $t("common.collections") }}</router-link
            >
            <router-link
              class="topnav-link focus-border_inset"
              :to="{ name: 'Monitoring' }"
              >{{ $t("pages.agent.overview.monitoring") }}</router-link
            >
            <router-link
              v-if="$store.getters['user/authorizeManager']"
              class="topnav-link focus-border_inset"
              :to="{ name: 'Admin' }"
              >{{ $t("common.administration") }}</router-link
            >
          </ul>
        </nav>
      </div>
      <transition mode="out-in" name="fade">
        <ClientMenu
          v-if="clientIdentifier"
          :signedIn="true"
          :name="$store.getters['user/fullName']"
          :identifier="clientIdentifier"
          :screenSizeOverlay="screenSizeOverlay"
        >
          <template v-slot:items>
            <ClientMenuItem>
              <calcite-button @click="signout()" scale="l" width="full">{{
                $t("actions.signOut")
              }}</calcite-button>
            </ClientMenuItem>
          </template>
        </ClientMenu>
      </transition>
    </div>
  </header>
</template>

<script>
import ClientMenu from "./ClientMenu";
import ClientMenuItem from "./ClientMenuItem";
import Brand from "./Brand";

export default {
  name: "NavTop",
  components: {
    ClientMenu,
    ClientMenuItem,
    Brand,
  },
  props: {},
  data() {
    return {
      canvasActive: false,
      canvasHeight: 0,
      screenSizeOverlay: false,
    };
  },
  computed: {
    clientIdentifier() {
      return this.$store.state.user.profile?.username;
    },
  },
  methods: {
    toggleCanvas() {
      this.canvasHeight = window.innerHeight;
      this.canvasActive = !this.canvasActive;

      //Disable scrolling when menu is open
      if (this.canvasActive) {
        document.querySelector("body").style.height = window.innerHeight + "px";
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.height = "100vh";
        document.querySelector("body").style.overflowY = "auto";
      }
    },
    signout() {
      this.canvasActive = false;
      document.querySelector("body").style.height = "100vh";
      document.querySelector("body").style.overflowY = "auto";

      this.$router.push({ path: "/signin" });
    },
    getDimensions() {
      const width = window.innerWidth;
      if (width < 1024) {
        this.screenSizeOverlay = true;
      } else {
        this.screenSizeOverlay = false;
      }
    },
  },
  created() {
    this.$on("topnav:menu:click", this.toggleCanvas);
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    this.getDimensions();
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>

<style scoped lang="scss">
.esri-header-canvas {
  top: 50px;
}
.topnav-wrapper {
  background-color: var(--calcite-ui-foreground-1);
  position: relative;
  z-index: 100;
  top: 0;
}

.topnav {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topnav-links {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  background-color: transparent;
}
.topnav-link {
  height: 100%;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  text-decoration: none;
  align-items: center;
  border-bottom: 1px solid transparent;
  border-top: 2px solid transparent;
  box-shadow: inset 0 -3px 0 0 transparent;
  color: var(--calcite-ui-text-2);
  transition: color 150ms ease-in-out;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
}
.topnav-link:hover {
  color: var(--calcite-ui-text-1);
  box-shadow: inset 0 -3px 0 0 rgba(0, 121, 193, 0.5);
}
.topnav-link.active {
  color: var(--calcite-ui-text-1);
  box-shadow: inset 0 -3px 0 0 var(--calcite-ui-brand);
}
</style>
