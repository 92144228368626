const { clearStorage } = require("../../utils/storage");
import { isEqual } from "lodash";

/**
 * STATE
 */
const state = {
  auth: null,
  profile: null,
  question: null,
  favorites: [],
  isInitialized: false,
};

/**
 * GETTERS
 */
const getters = {
  id: (state) => {
    return state.profile?.id;
  },
  isThemeDark: (state) => {
    let dark = false;

    // Attempt to get theme from profile
    if (state.profile?.settings.is_dark_theme_enabled) {
      dark = true;
    }
    // Attempt to get theme from
    else if (!state.profile && localStorage.getItem("theme")) {
      dark = localStorage.getItem("theme") === "dark";
    }
    return dark;
  },
  analysis: (state) => {
    return state.profile?.settings?.analysis;
  },
  verticesEnabled: (state) => {
    return state.profile?.settings.vertices_enabled ?? true;
  },
  fullName: (state) => {
    return `${state.profile?.name_first} ${state.profile?.name_last}`;
  },
  analysisFavorites: (state) => {
    return state.favorites.filter(
      (fav) => fav.attributes.favorite_type === "analysis"
    );
  },
  alertFavorites: (state) => {
    return state.favorites.filter(
      (fav) => fav.attributes.favorite_type === "alert"
    );
  },
  componentFavorites: (state) => {
    return state.favorites.filter(
      (fav) => fav.attributes.favorite_type === "component"
    );
  },
  collectionFavorites: (state) => {
    return state.favorites.filter(
      (fav) => fav.attributes.favorite_type === "collection"
    );
  },
  isFavorite: (state) => (id, type) => {
    return state.favorites.find(
      (fav) =>
        fav.attributes.favorite_id === id &&
        fav.attributes.favorite_type === type
    );
  },
  role: (state) => {
    return state.profile?.role;
  },
  // For Admins only
  authorizeAdmin() {
    return state.profile?.role === "admin";
  },
  // For Admins and Managers
  authorizeManager() {
    return ["manager", "admin"].includes(state.profile?.role);
  },
  userRelated: (state) => (id) => {
    return state.profile?.id === id;
  },
};

/**
 * MUTATIONS
 */
const mutations = {
  clearProfile(state) {
    state.profile = null;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async setTheme({ getters }) {
    // Add theme class to root HTML tag
    const html = document.getElementsByTagName("HTML")[0];
    html.className = getters.isThemeDark ? "calcite-theme-dark" : "";
  },
  async signIn({ dispatch }, { username, password, remember_me }) {
    await this._vm.$api.auth.signIn({ username, password, remember_me });
    await dispatch("loadProfile");
  },
  async loadProfile({ state, dispatch }, profileData) {
    const { favorites, attributes, question } =
      profileData ?? (await this._vm.$api.auth.getProfile());
    state.profile = attributes;
    state.question = question;
    state.favorites = favorites;
    state.isInitialized = true;

    // If the user hasn't set theme, update profile
    if (state.profile.settings?.is_dark_theme_enabled === undefined) {
      await dispatch("updateSettings", { is_dark_theme_enabled: false });
    }
    // Add to local storage
    localStorage.setItem(
      "theme",
      state.profile.settings.is_dark_theme_enabled ? "dark" : "light"
    );
    dispatch("setTheme");
  },
  async updateSettings({ state, dispatch }, newSettings = {}) {
    // Update theme under profile
    const { user } = await this._vm.$api.auth.updateProfile({
      ...state.profile,
      settings: {
        ...state.profile.settings,
        ...newSettings,
      },
    });
    await dispatch("loadProfile", user);
  },
  async toggleFavorite({ state, getters }, { id, type }) {
    const isFavorite = getters.isFavorite(id, type);
    let favorites = [];
    if (isFavorite) {
      favorites = await this._vm.$api.auth.deleteFavorite(
        isFavorite.attributes.id
      );
    } else {
      favorites = await this._vm.$api.auth.addFavorite(id, type);
    }
    state.favorites = favorites;
  },
  clearProfile(context) {
    context.commit("clearProfile");
    clearStorage();
  },
  async updateTableConfig({ state, dispatch }, { table_id, columns, view }) {
    if (table_id) {
      const ref = view ? `${table_id}-${view}` : table_id;
      const tables = state.profile.settings.tables;
      const equal = isEqual(columns, tables?.[ref]);
      if (!equal) {
        await dispatch("updateSettings", {
          tables: {
            ...(tables && { ...tables }),
            [ref]: columns,
          },
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
