import store from "@/store";

const _ROUTES = [
  //Sign in
  {
    path: "/signin",
    component: () => import("@/pages/signin/Root"),
    children: [
      {
        path: "",
        name: "Signin",
        component: () => import("@/pages/signin/SigninCard"),
      },
      {
        path: "/signin/recoverpassword",
        name: "PasswordRecover",
        component: () => import("@/pages/signin/PasswordRecoverCard"),
      },
      {
        path: "/signin/completeprofile",
        name: "CompleteProfile",
        component: () => import("@/pages/signin/CompleteProfileCard"),
      },
    ],
  },

  // Setup
  {
    path: "/setup",
    mode: ["agent", "server"],
    name: "Setup",
    component: () => import("@/pages/setup"),
  },

  // Unauthorized
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("@/pages/Unauthorized"),
  },

  // Account
  {
    path: "/account/",
    name: "Account",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/account/Root"),
    redirect: "/account/profile",
    children: [
      {
        path: "/account/profile",
        name: "Profile",
        component: () => import("@/pages/account/Profile"),
      },
      {
        path: "/account/favorites",
        name: "Favorites",
        component: () => import("@/pages/account/favorites/Root"),
        redirect: "/account/favorites/alerts",
        children: [
          {
            path: "components",
            name: "FavoritesComponents",
            component: () => import("@/pages/monitoring/ComponentsTable"),
          },
          {
            path: "collections",
            name: "FavoritesCollections",
            component: () => import("@/pages/collections/CollectionsList"),
          },
          {
            path: "alerts",
            name: "FavoritesAlerts",
            component: () => import("@/pages/alerts/AlertsTable"),
          },
          {
            path: "analyses",
            name: "FavoritesAnalyses",
            component: () => import("@/pages/analyses/AnalysesList"),
          },
        ],
      },
      {
        path: "/account/settings",
        name: "AccountSettings",
        component: () => import("@/pages/account/settings/Root"),
        redirect: "/account/settings/appearance",
        children: [
          {
            path: "appearance",
            name: "AccountSettingsAppearance",
            component: () =>
              import("@/pages/account/settings/AccountSettingsAppearance"),
          },
        ],
      },
    ],
  },

  // Home
  {
    path: "/",
    name: "Home",
    mode: ["agent", "server"],
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/Home"),
  },

  // Alerts list
  {
    path: "/alerts",
    name: "Alerts",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/alerts/Root"),
    redirect: "alerts/general",
    children: [
      {
        path: "general",
        name: "AlertsGeneral",
        component: () => import("@/pages/alerts/AlertsTable"),
      },
    ],
  },

  // Alert
  {
    path: "/alerts/:alertId",
    name: "Alert",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/alerts/alert/Root"),
    redirect: "alerts/:alertId/overview",
    children: [
      {
        path: "overview",
        name: "AlertOverview",
        component: () => import("@/pages/alerts/alert/AlertOverview"),
      },
    ],
  },

  // Analysis list
  {
    path: "/analysis",
    name: "Analyses",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    redirect: "/analysis/general",
    component: () => import("@/pages/analyses/Root"),
    children: [
      {
        path: "general",
        name: "AnalysesGeneral",
        component: () => import("@/pages/analyses/AnalysesList"),
      },
    ],
  },

  // Analysis
  {
    path: "/analysis/:analysisId",
    name: "Analysis",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/analyses/analysis/Root"),
    redirect: "/analysis/:analysisId/overview",
    children: [
      {
        path: "overview",
        name: "AnalysisOverview",
        component: () => import("@/pages/analyses/analysis/AnalysisOverview"),
      },
      {
        path: "settings",
        name: "AnalysisSettings",
        component: () => import("@/pages/analyses/analysis/settings/Root"),
        redirect: "settings/general",
        children: [
          {
            path: "general",
            name: "AnalysisSettingsGeneral",
            component: () =>
              import(
                "@/pages/analyses/analysis/settings/AnalysisSettingsGeneral"
              ),
          },
        ],
      },
    ],
  },

  // Collections list
  {
    path: "/collections",
    name: "Collections",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/collections/Root"),
    redirect: "/collections/general",
    children: [
      {
        path: "general",
        name: "CollectionsGeneral",
        component: () => import("@/pages/collections/CollectionsList"),
      },
    ],
  },

  // Collection
  {
    path: "/collections/:collectionId",
    name: "Collection",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/collections/collection/Root"),
    redirect: "collections/:collectionId/overview",
    children: [
      {
        path: "overview",
        name: "CollectionOverview",
        component: () =>
          import("@/pages/collections/collection/CollectionOverview"),
      },
      {
        path: "components",
        name: "CollectionComponents",
        component: () => import("@/pages/monitoring/ComponentsTable"),
      },
      {
        path: "alerts",
        name: "CollectionAlerts",
        component: () => import("@/pages/alerts/AlertsTable"),
      },
      {
        path: "settings",
        name: "CollectionSettings",
        meta: { showGlobalNav: true, authorize: ["manager", "admin"] },
        component: () => import("@/pages/collections/collection/settings/Root"),
        redirect: "settings/general",
        children: [
          {
            path: "general",
            name: "CollectionSettingsGeneral",
            component: () =>
              import(
                "@/pages/collections/collection/settings/CollectionSettingsGeneral"
              ),
          },
          {
            path: "expression",
            name: "CollectionSettingsExpression",
            component: () =>
              import(
                "@/pages/collections/collection/settings/CollectionSettingsExpression"
              ),
          },
          {
            path: "notifications",
            name: "CollectionSettingsNotifications",
            component: () => import("@/pages/notifications/NotificationsTable"),
          },
          {
            path: "notifications/:notificationId",
            name: "CollectionSettingsNotificationOverview",
            component: () =>
              import("@/pages/notifications/NotificationOverview"),
            children: [
              {
                path: "logs",
                name: "CollectionSettingsNotificationLogs",
              },
            ],
          },
          {
            path: "incidents",
            name: "CollectionSettingsIncidents",
            component: () => import("@/pages/incidents/IncidentsTable"),
          },
          {
            path: "incidents/:incidentId",
            name: "CollectionSettingsIncidentOverview",
            component: () => import("@/pages/incidents/IncidentOverview"),
          },
        ],
      },
    ],
  },

  // Monitoring
  {
    path: "/monitoring",
    name: "Monitoring",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/monitoring/Root"),
    redirect: "monitoring/components",
    children: [
      {
        path: "components",
        name: "Components",
        component: () => import("@/pages/monitoring/ComponentsTable"),
      },
      // {
      //   path: "metrics",
      //   name: "MetricsPage",
      //   meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
      //   component: () => import("@/pages/monitoring/MetricsPage"),
      // },
      {
        path: "agents",
        name: "Agents",
        meta: {
          showGlobalNav: true,
          authorize: ["manager", "admin"],
        },
        component: () => import("@/pages/monitoring/AgentsTable"),
      },
    ],
  },

  // Component
  {
    path: "/monitoring/components/:componentId",
    name: "Component",
    meta: { showGlobalNav: true, authorize: ["viewer", "manager", "admin"] },
    component: () => import("@/pages/component/Root"),
    redirect: "monitoring/components/:componentId/overview",
    children: [
      {
        path: "overview",
        name: "ComponentOverview",
        component: () => import("@/pages/component/ComponentOverview"),
      },
      {
        path: "metrics",
        name: "ComponentMetrics",
        component: () => import("@/pages/metrics/MetricsTable"),
      },
      {
        path: "metrics/:id",
        name: "ComponentMetric",
        component: () => import("@/pages/metrics/MetricOverview"),
      },
      {
        path: "catalog",
        name: "ComponentCatalog",
        props: { source: "Component" },
        component: () => import("@/pages/monitoring/ComponentsTable"),
      },
      {
        path: "logs",
        name: "ComponentLogs",
        meta: { showGlobalNav: true },
        component: () => import("@/pages/component/ComponentLogsTable"),
      },
      {
        path: "settings",
        name: "ComponentSettings",
        meta: { showGlobalNav: true, authorize: ["manager", "admin"] },
        component: () => import("@/pages/component/settings/Root"),
        redirect: "settings/general",
        children: [
          {
            path: "general",
            name: "ComponentSettingsGeneral",
            component: () =>
              import("@/pages/component/settings/ComponentSettingsGeneral"),
          },
          {
            path: "observers",
            name: "ComponentSettingsObservers",
            component: () => import("@/pages/observers/ObserversTable"),
            children: [
              {
                path: ":observerId",
                name: "ComponentSettingsObserver",
                props: true,
                component: () => import("@/pages/observers/ObserverOverview"),
                children: [
                  {
                    path: "logs",
                    name: "ComponentSettingsObserverLogs",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  // Admin
  {
    path: "/admin",
    name: "Admin",
    meta: { showGlobalNav: true, authorize: ["manager", "admin"] },
    component: () => import("@/pages/admin/Root"),
    redirect: "admin/general",
    children: [
      {
        path: "general",
        name: "AdminGeneral",
        component: () => import("@/pages/admin/general/Root"),
        redirect: () => {
          if (store.getters["user/authorizeAdmin"]) {
            return { path: "/admin/general/server" };
          } else {
            return { path: "/admin/general/notifications" };
          }
        },
        children: [
          {
            path: "server",
            name: "AdminServer",
            meta: { authorize: ["admin"] },
            component: () => import("@/pages/admin/general/Server"),
          },
          {
            path: "database",
            name: "AdminDatabase",
            meta: { authorize: ["admin"] },
            component: () => import("@/pages/admin/general/Database"),
          },
          {
            path: "password-policy",
            name: "AdminPasswordPolicy",
            meta: { authorize: ["admin"] },
            component: () => import("@/pages/admin/general/PasswordPolicy"),
          },
          {
            path: "monitor-license-info",
            name: "MonitorLicenseInfo",
            meta: { authorize: ["admin"] },
            component: () => import("@/pages/admin/general/LicenseInfo"),
          },
          {
            path: "email",
            name: "AdminEmail",
            meta: { authorize: ["admin"] },
            component: () => import("@/pages/admin/general/Email"),
          },
          {
            path: "notifications",
            name: "AdminNotifications",
            component: () => import("@/pages/notifications/NotificationsTable"),
          },
          {
            path: "notifications/:notificationId",
            name: "AdminNotificationOverview",
            component: () =>
              import("@/pages/notifications/NotificationOverview"),
            children: [
              {
                path: "logs",
                name: "AdminNotificationLogs",
              },
            ],
          },
          {
            path: "incidents",
            name: "AdminIncidents",
            component: () => import("@/pages/incidents/IncidentsTable"),
          },
          {
            path: "incidents/:incidentId",
            name: "AdminIncidentOverview",
            component: () => import("@/pages/incidents/IncidentOverview"),
          },
          {
            path: "labels",
            name: "AdminLabels",
            component: () => import("@/pages/admin/general/LabelsTable"),
            meta: { authorize: ["manager", "admin"] },
          },
        ],
      },
      {
        path: "users",
        name: "AdminUsers",
        meta: { authorize: ["admin"] },
        component: () => import("@/pages/admin/UsersTable"),
      },
      {
        path: "logs",
        name: "AdminLogs",
        component: () => import("@/pages/logs/LogsTable"),
      },
    ],
  },

  // Agent
  {
    path: "/monitoring/agents/:agentId",
    name: "Agent",
    meta: { showGlobalNav: true, authorize: ["manager", "admin"] },
    component: () => import("@/pages/agent/Root"),
    redirect: "monitoring/agents/:agentId/overview",
    children: [
      {
        path: "overview",
        name: "AgentOverview",
        component: () => import("@/pages/agent/AgentOverview"),
      },
      {
        path: "catalog",
        name: "AgentCatalog",
        component: () => import("@/pages/monitoring/ComponentsTable"),
      },
      {
        path: "settings",
        name: "AgentSettings",
        component: () => import("@/pages/agent/settings/Root"),
        redirect: "settings/general",
        children: [
          {
            path: "general",
            name: "AgentSettingsGeneral",
            component: () =>
              import("@/pages/agent/settings/AgentSettingsGeneral"),
          },
        ],
      },
    ],
  },

  // System Error
  {
    path: "/systemError",
    mode: ["agent", "server"],
    name: "SystemError",
    meta: { showGlobalNav: true },
    component: () => import("@/pages/SystemError"),
  },
  // Wild card
  {
    path: "*",
    mode: ["agent", "server"],
    redirect: "/",
  },
];

for (const route of _ROUTES) {
  route.mode = route.mode ?? ["server"];
}

export default _ROUTES;
