import store from "@/store";
import { get } from "lodash";

/**
 * STATE
 */
const state = {
  monitor: {},
  admin: {},
  isInitialized: false,
};

/**
 * GETTERS
 */
const getters = {
  version: (state) => {
    return state.monitor.agents.version;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async load({ state }) {
    try {
      const { resources } = await this._vm.$api.monitor.index();

      state.monitor = Object.assign(
        {},
        ...(await Promise.all(
          resources.map((resource) =>
            this._vm.$api.monitor[resource]
              ?.index()
              .then((res) => ({ [resource]: res }))
          )
        ))
      );

      if (["admin", "manager"].includes(store.getters["user/role"])) {
        const adminSchemas = [
          ["users", "admin.security.users"],
          ["logs", "admin.logs"],
        ];
        state.admin = Object.assign(
          {},
          ...(await Promise.all(
            adminSchemas.map(([entity, route]) =>
              get(this._vm.$api, route)
                .index()
                .then((res) => ({ [entity]: res }))
            )
          ))
        );
      }
    } catch (err) {
      this.$store.dispatch("toasts/add", {
        status: "error",
        auto: true,
        message: err.error.message || this.$t("globalErrorMessages.generic"),
      });
    }
    state.isInitialized = true;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
};
