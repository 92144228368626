<template>
  <div class="esri-header-client" ref="navClientMenu">
    <div class="esri-header-account">
      <button
        v-if="signedIn"
        @click="toggleActive()"
        class="esri-header-account-control esri-header-account-control--signedin calcite-focus-border_inset"
        :aria-expanded="active"
        :aria-haspopup="!active"
        :tabindex="screenSizeOverlay ? -1 : undefined"
        js-focus
      >
        <div class="esri-header-account-image" js-focus>
          <calcite-avatar
            :full-name="name"
            :key="this.$store.getters['user/isThemeDark']"
          />
        </div>
        <span class="user-name">
          <span class="esri-header-account-name">{{ name }}</span>
          <span class="esri-header-account-id">{{ identifier }}</span>
        </span>
      </button>
      <button
        v-else
        @click.self="$emit('click')"
        class="esri-header-account-control esri-header-account-control--signin"
        :tabindex="screenSizeOverlay ? -1 : undefined"
      >
        {{ $t("actions.signIn") }}
      </button>
      <div
        class="esri-header-account-menu"
        role="group"
        :aria-expanded="active"
        :aria-hidden="!active"
      >
        <button class="esri-header-account-content-toggle">
          {{ $t("common.profile") }}
        </button>
        <div class="esri-header-account-content-info">
          <div class="esri-header-account-content-image">
            <calcite-avatar
              :full-name="name"
              :key="this.$store.getters['user/isThemeDark']"
              class="profile-avatar_large font-size-6"
            />
          </div>

          <span class="esri-header-account-content-name">{{ name }}</span>
          <span class="esri-header-account-content-id">{{ identifier }}</span>
          <span class="esri-header-account-content-group">{{ group }}</span>
          <span class="esri-header-account-content-version">{{ version }}</span>
        </div>
        <ul
          class="esri-header-account-content-menu"
          role="navigation"
          aria-labelledby="esri-header-account-control"
        >
          <li
            class="esri-header-account-content-item margin-leader-quarter"
            @click="toggleActive()"
          >
            <router-link
              class="esri-header-account-content-link focus-border"
              :to="{ name: 'Profile' }"
              >{{ $t("pages.account.myProfile.title") }}</router-link
            >
          </li>
          <li
            class="esri-header-account-content-item margin-leader-quarter"
            @click="toggleActive()"
          >
            <router-link
              class="esri-header-account-content-link focus-border"
              :to="{ name: 'Favorites' }"
              >{{ $t("pages.account.myFavorites.title") }}</router-link
            >
          </li>
          <!-- <li class="esri-header-account-content-item margin-leader-quarter">
            <router-link
              class="esri-header-account-content-link focus-border"
              :to="{ name: 'MyComments' }"
              >{{ $t("pages.account.myComments.title") }}</router-link
            >
          </li> -->
          <li
            class="esri-header-account-content-item margin-leader-quarter"
            @click="toggleActive()"
          >
            <router-link
              class="esri-header-account-content-link focus-border"
              :to="{ name: 'AccountSettings' }"
              >{{ $t("pages.account.mySettings.title") }}</router-link
            >
          </li>
          <!-- <li class="esri-header-account-content-item margin-leader-quarter">
            <a
              class="esri-header-account-content-link focus-border"
              href="#user-menu-link-1"
              >{{ $t("actions.help") }}</a
            >
          </li> -->
        </ul>
        <ul class="esri-header-account-signin-menu">
          <slot name="items"></slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavClientMenu",
  components: {},
  props: {
    signedIn: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    identifier: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "",
    },
    screenSizeOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      version: null,
    };
  },
  watch: {
    $route() {
      this.active;
    },
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
      if (this.active) {
        document.addEventListener("click", this.clickedOutsite);
      } else {
        document.removeEventListener("click", this.clickedOutsite);
      }
      this.$emit("click");
      this.$parent.$emit("topnav:menu:click");
    },
    clickedOutsite(e) {
      const el = this.$refs.navClientMenu;
      const { target } = e;
      if (el !== target && !el.contains(target)) {
        this.toggleActive();
      }
    },
  },
  async beforeMount() {
    this.version =
      this.$store.getters["schema/version"] ?? this.$t("common.na");
  },
  destroyed() {
    document.removeEventListener("click", this.clickedOutsite);
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 1180px) {
  .user-name {
    display: none;
  }
  .esri-header-account-image {
    padding-left: 16px;
  }
}

.esri-header-account-menu {
  background-color: var(--calcite-ui-foreground-1);
  box-shadow: inset 0 0 0 1px var(--calcite-ui-border-1);
}

.esri-header-account-content-name {
  color: var(--calcite-ui-text-1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.esri-header-client {
  z-index: 1000;
  position: relative;
}
.esri-header-account-signin-menu {
  align-items: stretch;
}
.esri-header-account-content-menu {
  box-sizing: border-box;
}
.esri-header-account-control--signedin {
  height: 50px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  align-items: center;
  &:hover,
  &:active,
  &[aria-expanded="true"] {
    color: var(--calcite-ui-text-1);
  }
}
.profile-avatar_large {
  height: 120px;
  width: 120px;
}

.esri-header-account-content-image {
  box-shadow: 0 0 0 2px var(--calcite-ui-foreground-1),
    0 0 0 6px var(--calcite-ui-brand);
  display: flex;
  align-items: center;
  justify-content: center;
}

.esri-header-account-content-version {
  color: var(--calcite-ui-text-3);
  margin-top: 1rem;
  font-size: 13px;
}

.esri-header-account-content-link {
  color: var(--calcite-ui-brand);
  &:hover {
    color: var(--calcite-ui-brand-hover);
  }
}
</style>
