const RESOURCE_URL = "monitoring/components";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },

    query(query = {}) {
      if (!query.where || query.where.length === 0) query.where = "1=1";
      return connection.post(RESOURCE_URL + "/query", { json: query }).json();
    },

    resource(id) {
      return connection.get(RESOURCE_URL + `/${id}`).json();
    },

    register(fields) {
      return connection
        .post(RESOURCE_URL + "/register", {
          json: {
            ...fields,
          },
        })
        .json();
    },

    update(fields) {
      return connection
        .post(RESOURCE_URL + "/update", {
          json: {
            ...fields,
          },
        })
        .json();
    },

    unregister(ids) {
      return connection
        .post(RESOURCE_URL + "/unregister", { json: { ids: ids } })
        .json();
    },

    labels: {
      index() {
        return connection.post(RESOURCE_URL + "/labelsIndex").json();
      },
      attach({ id, label_ids }) {
        return connection
          .post(RESOURCE_URL + "/labels/attach", {
            json: {
              id,
              label_ids,
            },
          })
          .json();
      },
      detach({ id, label_ids }) {
        return connection
          .post(RESOURCE_URL + "/labels/detach", {
            json: {
              id,
              label_ids,
            },
          })
          .json();
      },
    },

    logs: {
      index() {
        return connection.get(RESOURCE_URL + "/logs").json();
      },

      query(query = {}) {
        if (!query.where || query.where.length === 0) query.where = "1=1";
        return connection
          .post(RESOURCE_URL + "/logs/query", { json: query })
          .json();
      },
    },
  };
}
