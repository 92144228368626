/**
 * STATE
 */
const state = {
  resource: {},
};

/**
 * GETTERS
 */
const getters = {};

/**
 * MUTATIONS
 */
const mutations = {
  alert(state, data) {
    state.resource = data;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async loadAlert(context, { id }) {
    let data = await this._vm.$api.monitor.alerts.query({
      where: `id = ${id}`,
      including: [
        {
          resource: "comments",
          orderbyfields: ["created_at desc"],
          including: [{ resource: "user" }],
        },
        { resource: "metrics" },
        { resource: "components" },
        { resource: "observers" },
      ],
    });
    data = data.features[0];
    context.commit("alert", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
