/**
 * STATE
 */
const state = {
  dateFilter: { start: undefined, end: undefined, preset: null },
  resource: {},
  observer: {},
  metric: {},
};

/**
 * GETTERS
 */
const getters = {
  metrics: (state) => {
    return state.resource.metrics;
  },
  observers: (state) => {
    return state.resource.observers;
  },
  storage: (state) => {
    return state.resource.children.filter((c) => c.type === "storage");
  },
};

/**
 * MUTATIONS
 */
const mutations = {
  dateFilter(state, data) {
    state.dateFilter = data;
  },
  component(state, data) {
    state.resource = data;
  },
  labels(state, data) {
    state.resource.labels = data;
  },
  // all observers
  observers(state, data) {
    state.resource = {
      ...state.resource,
      observers: data,
    };
  },
  observer(state, data) {
    state.observer = data;
  },
  // all metrics
  metrics(state, data) {
    state.resource = {
      ...state.resource,
      metrics: data,
    };
  },
  metric(state, data) {
    state.metric = data;
  },
  agent(state, data) {
    state.resource.agent = data;
  },
};

/**
 * ACTIONS
 */
const actions = {
  async loadComponent(context, { id }) {
    let data = await this._vm.$api.monitor.components.query({
      where: `id = ${id}`,
      including: [
        {
          resource: "collections",
          including: [
            {
              resource: "incidents",
              where: "state = 'active'",
            },
          ],
          orderbyfields: "name",
        },
        {
          resource: "parents",
        },
        {
          resource: "children",
          returnCountOnly: true,
        },
        {
          resource: "alerts",
          where: "closed_at IS NULL",
          returnCountOnly: true,
          orderbyfields: "name",
        },
        {
          resource: "labels",
        },
        {
          resource: "agent",
        },
      ],
    });

    data = data.features[0];
    context.commit("component", data);
  },
  async loadObserver(context, { observer_id }) {
    let data = await this._vm.$api.monitor.observers.query({
      where: `id = '${observer_id}'`,
      including: [
        {
          resource: "metrics",
        },
      ],
    });
    data = data.features[0];
    context.commit("observer", data);
  },
  async loadMetric(context, { metric_id }) {
    let data = await this._vm.$api.monitor.metrics.query({
      where: `id = '${metric_id}'`,
    });
    data = data.features[0];
    context.commit("metric", data);
  },
  async loadLabels(context) {
    let data = await this._vm.$api.monitor.components.query({
      where: `id = '${state.resource.attributes.id}'`,
      including: [
        {
          resource: "labels",
        },
      ],
    });

    data = data.features[0].labels;
    context.commit("labels", data);
  },
  async loadAgent(context, { component_id }) {
    let data = await this._vm.$api.monitor.agents.query({
      having: [
        {
          resource: "components",
          where: `id = ${component_id}`,
        },
      ],
    });

    data = data.features;
    context.commit("agent", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
