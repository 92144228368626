<template>
  <calcite-modal
    width="s"
    :open="open"
    close-button-disabled
    outside-close-disabled
    :escape-disabled="isPending"
    aria-labelledby="modal-title"
    @calciteModalClose="onClose()"
  >
    <h1 slot="header" id="modal-title" class="margin-0 font-size-1">
      {{ $t("pages.settings.general.server.restartServer") }}
    </h1>
    <div slot="content">
      <calcite-scrim v-if="isPending" loading />
      <p class="padding-leader-1">
        {{ $t("pages.settings.general.server.restartServerParagraph") }}
      </p>

      <!-- ERROR MESSAGE -->
      <NoticeApiError v-if="error" :error="error" />
    </div>
    <!-- Modal Footer -->
    <calcite-button
      @click="close()"
      :disabled="isPending"
      intl-close
      slot="secondary"
      appearance="outline"
      >{{ $t("actions.cancel") }}</calcite-button
    >
    <calcite-button
      @click="restartServer()"
      slot="primary"
      :loading="isPending"
      :disabled="isPending"
      >{{
        isPending ? $t("states.restarting") + "..." : $t("actions.restart")
      }}</calcite-button
    >
  </calcite-modal>
</template>

<script>
// App Components
import NoticeApiError from "@/partials/other/NoticeApiError";

// Mixins
import modalMixin from "@/mixins/modals-mixin";

export default {
  name: "ServerRestartModal",
  mixins: [modalMixin],
  components: { NoticeApiError },
  data() {
    return {
      isInitialized: true,
      error: null,
    };
  },
  methods: {
    async restartServer() {
      try {
        this.submitStatus = "PENDING";
        await this.$api.admin.system.restart();
        // Initial pause to allow the server to start restarting
        setTimeout(this.checkIsServerBackUp, 5000);
      } catch (err) {
        this.submitStatus = "ERROR";
        this.error = err.error;
      }
    },
    async checkIsServerBackUp() {
      try {
        await this.$api.admin.system.index();
        this.$store.dispatch("toasts/add", {
          status: "success",
          title: this.$t("common.success"),
          message: this.$t("globalSuccessMessages.restartServer"),
        });

        this.close();
      } catch {
        setTimeout(this.checkIsServerBackUp, 500);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global-component-styles/forms.scss";
</style>
