import Vue from "vue";
import i18n from "@/i18n.js";

// Third Party
import humanizeDuration from "humanize-duration";
import { format as timeAgo } from "timeago.js";

const capitalize = Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("lowercase", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toLowerCase() + value.slice(1);
});

Vue.filter("title", function (value) {
  if (!value) return "";
  value = value.toString();
  return value
    .split(" ")
    .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
    .join(" ");
});

Vue.filter("shorten", function (value, length = 40) {
  if (!value) return "";
  let str = value.toString();
  str = str.slice(0, length);
  return `${str}${value.length > length ? "..." : ""}`;
});

const datetime = Vue.filter("datetime", function (dateString) {
  if (!dateString) {
    return null;
  }
  // alternative might be: new Date(val).toLocaleString()
  return new Intl.DateTimeFormat("en-us", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
    .format(new Date(dateString))
    .replace(/^0(?:0:0?)?/, ""); // remove leading zeros
});

Vue.filter("month", function (dateString) {
  if (!dateString) {
    return null;
  }
  // alternative might be: new Date(val).toLocaleDateString()
  return new Intl.DateTimeFormat("en-us", {
    month: "short",
    year: "numeric",
  }).format(new Date(dateString));
});

const date = Vue.filter("date", function (dateString) {
  if (!dateString) {
    return null;
  }
  // alternative might be: new Date(val).toLocaleDateString()
  return new Intl.DateTimeFormat("en-us", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  })
    .format(new Date(dateString))
    .replace(/\b0/g, ""); // remove leading zeros
});

Vue.filter("longDate", function (dateString) {
  if (!dateString) {
    return null;
  }
  // alternative might be: new Date(val).toLocaleDateString()
  return new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(dateString));
});

const time = Vue.filter("time", function (dateString) {
  if (!dateString) {
    return null;
  }
  // alternative might be: new Date(val).toLocaleDateString()
  return new Intl.DateTimeFormat("en-us", {
    hour: "2-digit",
    minute: "2-digit",
  })
    .format(new Date(dateString))
    .replace(/^0(?:0:0?)?/, ""); // remove leading zeros
});

Vue.filter("month", function (dateString) {
  if (!dateString) {
    return "---";
  }
  // alternative might be: new Date(val).toLocaleDateString()
  return new Intl.DateTimeFormat("en-us", {
    month: "short",
    year: "numeric",
  }).format(new Date(dateString));
});

Vue.filter("timeAgo", function (date) {
  return timeAgo(new Date(date));
});

Vue.filter("duration", function (duration = 0, unit = "m") {
  // Converts specified unit to ms
  const unitConvert = {
    ms: 1,
    s: 1000,
    m: 60000,
    h: 3600000,
    d: 86400000,
    w: 604800000,
  };
  duration = Math.abs(duration * unitConvert[unit]);
  // todo localization
  const shortHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
  });
  return shortHumanizer(duration, { spacer: "" });
});

Vue.filter("range", function (s, e, format = "date") {
  const formats = { date, datetime };
  const start = new Date(s);
  const end = new Date(e);

  // Duration less than 24 hrs and not overlapping days
  if (start.getDate() === end.getDate()) {
    return i18n.t("common.fromToOn", {
      from: time(start),
      to: time(end),
      date: date(start),
    });
  }

  // Duration more than 24 hrs or overlapping days
  return `${formats[format](start)} - ${formats[format](end)}`;
});

Vue.filter("trueFalse", function (bool) {
  return !!bool;
});

Vue.filter("onOff", function (bool) {
  return bool ? i18n.t("states.on") : i18n.t("states.off");
});

Vue.filter("yesNo", function (bool) {
  return capitalize(bool ? i18n.t("common.yes") : i18n.t("common.no"));
});

Vue.filter("number", function (num) {
  return new Intl.NumberFormat("en-us", {}).format(num);
});

Vue.filter("commaString", function (arr) {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  const last = arr.at(-1);
  arr.pop();
  return `${arr.join(", ")} ${i18n.t("common.and")} ${last}`;
});
