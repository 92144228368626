<template>
  <div :class="{ 'load-initial_center': center }">
    <calcite-loader class="no-padding" />
    <h4 v-if="showText" class="leader-1 text-center font-size-2">
      {{ text || $t("states.loading") }}
    </h4>
  </div>
</template>
<script>
export default {
  name: "LoadInitial",
  components: {},
  props: {
    text: {
      type: String,
      default: "Loading...",
    },
    center: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.load-initial_center {
  position: absolute;
  left: 50%;
  margin-top: 4rem;
  transform: translate(-50%, -50%);
}
calcite-loader.no-padding {
  --calcite-loader-padding: 0;
}
</style>
