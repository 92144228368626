import Logs from "./logs";
import Monitor from "./monitor";
import Database from "./database";
import Security from "./security";
import System from "./system";

const RESOURCE_URL = "admin";

export default function (connection) {
  return {
    index() {
      return connection.get(RESOURCE_URL).json();
    },
    logs: Logs(connection),
    monitor: Monitor(connection),
    security: Security(connection),
    system: System(connection),
    database: Database(connection),
  };
}
