/**
 * STATE
 */
const state = {
  promptRestart: false,
  setup: null,
  serverLogin: null,
  createdAgent: null,
};

/**
 * GETTERS
 */

const getters = {
  maxRecordCount: (state) => () => {
    return state.setup.system.api.maxRecordCount;
  },
  serverLogin: (state) => {
    return state.serverLogin;
  },
  createdAgent: (state) => {
    return state.createdAgent;
  },
  isSetup: (state) => {
    return state.setup?.isSetup ?? false;
  },
};

/**
 * MUTATIONS
 */
const mutations = {
  promptRestart(state, bool) {
    state.promptRestart = bool;
  },
  setupProperties(state, setup) {
    state.setup = setup.properties;
  },
  serverLogin(state, serverLogin) {
    state.serverLogin = serverLogin;
  },
  createdAgent(state, createdAgent) {
    state.createdAgent = createdAgent;
  },
};

/**
 * ACTIONS
 */
const actions = {
  promptServerRestart(context, bool) {
    context.commit("promptRestart", bool);
  },
  async fetchSetup(context) {
    let setup = await this._vm.$api.setup.index();
    context.commit("setupProperties", setup);
  },
  setServerLogin(context, serverLogin) {
    context.commit("serverLogin", serverLogin);
  },
  setCreatedAgent(context, createdAgent) {
    context.commit("createdAgent", createdAgent);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
