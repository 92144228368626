// COMMON HTTP CLIENT
import connection from "./client";

// API RESOURCES
import Admin from "./admin";
import Auth from "./auth";
import Monitor from "./monitor";
import Setup from "./setup";

export default {
  admin: Admin(connection),
  auth: Auth(connection),
  monitor: Monitor(connection),
  setup: Setup(connection),
};
