import Vue from "vue";
import VueRouter from "vue-router";

import routes from "./routes";
import store from "@/store";

function init() {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE,
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes: routes.filter(({ mode }) => mode.includes(Vue.prototype.$mode)),
  });

  window.addEventListener("agm.signout", () => {
    if (!["Signin", "Setup"].includes(router.currentRoute.name)) {
      router.push({ name: "Signin" });
    }
  });

  window.addEventListener("agm.systemerror", () => {
    if (router.currentRoute.name !== "SystemError") {
      router.push({ name: "SystemError" });
    }
  });

  /**
   * REMEMBER: BEFOREEACH MUST ONLY HAVE A SINGLE NEXT() CALL
   * THROUGHOUT THE HOOK LOGIC
   */
  router.beforeEach(async (to, from, next) => {
    // FIRST LOAD CORE DATA
    if (store.state.server.setup === null) {
      await store.dispatch("server/fetchSetup");
    }

    // SECOND: HANDLE UNAUTHORIZED REDIRECTS
    // UNAUTH REDIRECTS: FROM NODE.JS
    if (Object.keys(to.query).find((key) => key.toLowerCase() === "redirect")) {
      next({ path: to.query.redirect, query: {} });
    }
    // UNAUTH REDIRECTS:IF SETUP INCOMPLETE AND NOT ROUTING TO SETUP - REROUTE THERE
    else if (!store.state.server.setup.system.isSetup && to.name !== "Setup") {
      next({ path: "/setup", replace: true });
    }
    // UNAUTH REDIRECTS: IF SETUP COMPLETE AND ROUTING TO SETUP - REROUTE TO HOME
    else if (store.state.server.setup.system.isSetup && to.name === "Setup") {
      next({ path: "/", replace: true });
    } else if (Vue.prototype.$mode === "server") {
      // THIRD ASSUME WE CAN SIGN IN NOW
      // IF ROUTED TO A PAGE REQUIRING AUTHORIZATION - LOAD USER PROFILE
      if (
        to.matched.some((record) => record.meta.authorize) &&
        !store.state.user.isInitialized
      ) {
        await store.dispatch("user/loadProfile");
      }
      // IF NOT INITIALIZED - LOAD MONITOR SCHEMA
      if (store.state.user.profile && !store.state.schema.isInitialized) {
        await store.dispatch("schema/load");
      }
      // IF RESTRICTED BY USER PERMISSION REROUTE
      if (to.matched.some((record) => record.meta.authorize)) {
        let unauthorized = false;
        to.matched.forEach((record) => {
          if (
            record.meta.authorize &&
            !record.meta.authorize.includes(store.getters["user/role"])
          ) {
            unauthorized = true;
          }
        });
        if (unauthorized) {
          next({ path: "/unauthorized", replace: true });
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  });
  return router;
}

// router.afterEach(async (to, from) => {
//   if (store.state.user.profile && !store.state.schema.isInitialized) {
//     // Load fields for components, collections, alerts, etc
//     // once user profile exists
//     store.dispatch("schema/load");
//   }
// });

export default init;
