<template>
  <li class="esri-header-account-content-item">
    <slot></slot>
  </li>
</template>

<script>
export default {};
</script>

<style scoped>
.esri-header-account-content-item {
  flex-grow: 1;
}
svg {
  display: inline-block;
  vertical-align: middle;
}
</style>
